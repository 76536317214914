<template>
  <div>
    <b-row class="mb-4">
      <b-col cols="12" offset-sm="1" sm="10">
        <h3>
          {{ $t('settings.project.projectType.themeSetting.title') }}
          -
          {{ $t('settings.project.projectType.themeSetting.direct_bottom.name') }}
        </h3>
        <hr>
      </b-col>
    </b-row>
    <component
      v-for="(themeConfigItem, index) in themeFieldConfig"
      :key="index"
      :is="themeConfigItem.fieldType"
      :handle="themeConfigItem.handle"
      :label="themeConfigItem.title"
      :desc="themeConfigItem.desc"
      :value-from-db="themeValues[themeConfigItem.handle]"
      @update-field="updateSettingValue($event, themeConfigItem.handle)"
    />
  </div>
</template>

<script>
import ImageUploadRow from '@/components/helper/ImageUploadRow.vue';
import CheckboxRow from '@/components/helper/CheckboxRow.vue';
import InputRow from '@/components/helper/InputRow.vue';

export default {
  name: 'ThemeSettingsDirectBottom',
  components: {
    CheckboxRow,
    ImageUploadRow,
    InputRow,
  },
  props: {
    settingValues: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      themeFieldConfig: [
        {
          handle: 'logoimage',
          title: this.$t('settings.project.projectType.themeSetting.direct_bottom.logoimage.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_bottom.logoimage.desc'),
          fieldType: 'ImageUploadRow',
        },
        {
          handle: 'title',
          title: this.$t('settings.project.projectType.themeSetting.direct_bottom.title.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_bottom.title.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'show_logo',
          title: this.$t('settings.project.projectType.themeSetting.direct_bottom.show_logo.title'),
          desc: null,
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'menuTags',
          title: this.$t('settings.project.projectType.themeSetting.direct_bottom.menuTags.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_bottom.menuTags.desc'),
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'navigation_background',
          title: this.$t('settings.project.projectType.themeSetting.direct_bottom.navigation_background.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_bottom.navigation_background.desc'),
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'pagination',
          title: this.$t('settings.project.projectType.themeSetting.direct_bottom.pagination.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_bottom.pagination.desc'),
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'sharing',
          title: this.$t('settings.project.projectType.themeSetting.direct_bottom.sharing.title'),
          desc: null,
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'sharing_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_bottom.sharing_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_bottom.sharing_tooltip.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'cookies',
          title: this.$t('settings.project.projectType.themeSetting.direct_bottom.cookies.title'),
          desc: null,
          fieldType: 'CheckboxRow',
        },
        {
          handle: 'cookies_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_bottom.cookies_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_bottom.cookies_tooltip.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'facebook',
          title: this.$t('settings.project.projectType.themeSetting.direct_bottom.facebook.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_bottom.facebook.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'facebook_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_bottom.facebook_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_bottom.facebook_tooltip.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'twitter',
          title: this.$t('settings.project.projectType.themeSetting.direct_bottom.twitter.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_bottom.twitter.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'twitter_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_bottom.twitter_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_bottom.twitter_tooltip.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'instagram',
          title: this.$t('settings.project.projectType.themeSetting.direct_bottom.instagram.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_bottom.instagram.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'instagram_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_bottom.instagram_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_bottom.instagram_tooltip.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'linkedin',
          title: this.$t('settings.project.projectType.themeSetting.direct_bottom.linkedin.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_bottom.linkedin.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'linkedin_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_bottom.linkedin_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_bottom.linkedin_tooltip.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'xing',
          title: this.$t('settings.project.projectType.themeSetting.direct_bottom.xing.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_bottom.xing.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'xing_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_bottom.xing_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_bottom.xing_tooltip.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'youtube',
          title: this.$t('settings.project.projectType.themeSetting.direct_bottom.youtube.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_bottom.youtube.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'youtube_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_bottom.youtube_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_bottom.youtube_tooltip.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'website',
          title: this.$t('settings.project.projectType.themeSetting.direct_bottom.website.title'),
          desc: null,
          fieldType: 'InputRow',
        },
        {
          handle: 'website_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_bottom.website_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_bottom.website_tooltip.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'print',
          title: this.$t('settings.project.projectType.themeSetting.direct_bottom.print.title'),
          desc: null,
          fieldType: 'InputRow',
        },
        {
          handle: 'print_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_bottom.print_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_bottom.print_tooltip.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'overview',
          title: this.$t('settings.project.projectType.themeSetting.direct_bottom.overview.title'),
          desc: null,
          fieldType: 'InputRow',
        },
        {
          handle: 'overview_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_bottom.overview_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_bottom.overview_tooltip.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'imprint',
          title: this.$t('settings.project.projectType.themeSetting.direct_bottom.imprint.title'),
          desc: null,
          fieldType: 'InputRow',
        },
        {
          handle: 'imprint_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_bottom.imprint_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_bottom.imprint_tooltip.desc'),
          fieldType: 'InputRow',
        },
        {
          handle: 'dataprivacy',
          title: this.$t('settings.project.projectType.themeSetting.direct_bottom.dataprivacy.title'),
          desc: null,
          fieldType: 'InputRow',
        },
        {
          handle: 'dataprivacy_tooltip',
          title: this.$t('settings.project.projectType.themeSetting.direct_bottom.dataprivacy_tooltip.title'),
          desc: this.$t('settings.project.projectType.themeSetting.direct_bottom.dataprivacy_tooltip.desc'),
          fieldType: 'InputRow',
        },
      ],
      themeValues: {
        logoimage: {
          url: null,
          width: null,
          height: null,
          focalpoint: {
            x: 50,
            y: 50,
          },
        },
        title: null,
        show_logo: true,
        menuTags: true,
        navigation: true,
        navigation_background: false,
        pagination: false,
        sharing: true,
        sharing_tooltip: null,
        cookies: true,
        cookies_tooltip: null,
        facebook: null,
        facebook_tooltip: null,
        twitter: null,
        twitter_tooltip: null,
        instagram: null,
        instagram_tooltip: null,
        linkedin: null,
        linkedin_tooltip: null,
        xing: null,
        xing_tooltip: null,
        youtube: null,
        youtube_tooltip: null,
        website: null,
        website_tooltip: null,
        print: null,
        print_tooltip: null,
        overview: null,
        overview_tooltip: null,
        imprint: null,
        imprint_tooltip: null,
        dataprivacy: null,
        dataprivacy_tooltip: null,
      },
    };
  },
  created() {
    // add the values to the data
    if (this.settingValues) {
      // eslint-disable-next-line array-callback-return
      Object.entries(this.settingValues).map((itemValue) => {
        const key = itemValue[0];
        const value = itemValue[1];
        if (this.themeValues.hasOwnProperty(key)) {
          this.themeValues[key] = value;
        }
      });
    }
    this.$emit('update-theme-settings', this.themeValues);
  },
  methods: {
    updateSettingValue(value, handle) {
      if (this.themeValues.hasOwnProperty(handle)) {
        this.themeValues[handle] = value;
        this.$emit('update-theme-settings', this.themeValues);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
